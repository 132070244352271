<template>
  <b-modal id="expenses-modal" title="Itens do layout" size="xl" hide-footer centered>
    <section>
      <ExpenseModuleTable 
        v-if="expenses.length"
        :items="expenses"
      />
      <b-row class="w-100 justify-content-end">
        <span class="tag">
          Valor total do layout: <strong>{{ getTotalValue() }}</strong>
        </span>
    </b-row>
    </section>
  </b-modal>
</template>

<script>
import { parseNumberToMoney } from '@/utils/moneyHelper'

export default {
  components: {
    ExpenseModuleTable: () => import('@/components/Tiss/components/ExpenseModuleTable'),
  },
  props: {
    expenses: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getTotalValue() {
      const total = this.expenses.reduce((acc, curr) => {
        return acc + (curr.quantity * (curr?.unitary_value ?? 0))
      }, 0)
      return parseNumberToMoney(total)
    },
  }
}
</script>


<style lang="scss" scoped>
.tag {
  background-color: var(--neutral-200);
  border-radius: 8px;
  padding: 15px;
  font-size: 14px;
  font-weight: 400;
  margin-top: 10px;
  color: var(--type-active);

  strong {
    color: var(--blue-500);
  }
}

</style>